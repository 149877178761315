// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bV_k4";
export var caseStudyBackground__lineColor = "bV_kW";
export var caseStudyHead__imageAbsolute = "bV_k9";
export var caseStudyHead__imageWrapper = "bV_kT";
export var caseStudyProjectsSection = "bV_k5";
export var caseStudyQuote__bgLight = "bV_k2";
export var caseStudyQuote__bgRing = "bV_k1";
export var caseStudySolution__ring = "bV_kZ";
export var caseStudyTech = "bV_k3";
export var caseStudy__bgDark = "bV_kS";
export var caseStudy__bgLight = "bV_kR";